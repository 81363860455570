// Grid Breakpoints
$breakpoints: (
    xs2: 350px,
    xs: 450px,
    xsm2: 400px,
    xsm: 480px,
    sm: 576px,
    md: 768px,
    md2: 900px,
    lg: 991.98px,
    xl: 1200px,
    xxl: 1320px,
    xxxl: 1400px,
);

// layout
$containerPadding: max(1.25rem, 5vw);
$container: calc(100vw - $containerPadding);
$container-2: calc(1300px - $containerPadding);
$container-3: calc(1410px - $containerPadding);
$minSideNavigation: 180px;
$minSideNavigationCollapsed: 100px;
$sideNavigation: 348px;
$sideNavigationCollapsed: 100px;
$dashboardRightSide: 404px;
$minDashboardRightSide: 230px;
$courseDetailsRight: 400px;
$eCard: 320px;
$chat-left-side: 12vw;
$chat-left-side-dec: 0vw;
$chat-left-channel-bar: 13vw;
$chat-left-channel-bar-dec: 0vw;
$chat-right-side: 20vw;
$chat-right-side-dec: 2vw;
$chat-header-height: 80px;
$chat-messages-width: 53vw;

// fonts
$font: 'Montserrat', sans-serif;
$btn-font: 'Poppins', sans-serif;
$font-2: 'Roboto Slab', serif;

// colors
$body-bg: #F4F7FC;
$editor-bg: #F7FFF7;
$section-bg: #F4F7FC;
$footer-bg: #D967040D;

$cta-btn: #D96704;
$cta-btn-hover: #F79403;
$primary: #025928;
$primary-2: #3C8C30;
$primary-3: #5e8af3;
$primary-4: #2e72fa;

$bg-color: #F4F7FC;
$description: #666666;
$description-2: #4A5569;
$dark-text: #2A3344;


$gradient: linear-gradient(180deg, #025928 0%, #3C8C30 100%);
$gradient-2: linear-gradient(180deg, #025928 0%, #13A692 100%);

$white: #ffffff;
$black: #000000;
$soft-stoke: #dddddd;
$red: #D81C1C;
$red-hover: #fc6262;

$secondary: #D5FFD5;
$secondary-btn-bg: #F7FFF7;
$secondary-btn-hover: #E4FFE4;
$secondary-btn-border: #3C8C30;

$secondary-underline: #fdd96b;
// $secondary : #fdd96b;
// $secondary: #ff8b26;
// $secondary-2: #ffab17;
// $secondary-3: #ffb27a;
$secondary-4: #dff6fe;

$tertiary-border: #025928;
$neutral: #111111;
$neutral-2: #353535;
$neutral-3: #5e5e5e;
$neutral-4: #b5b5b5;
$neutral-5: #f8f8f8;
$gray: #c3c3c3;
$success: #36b236;
$error: #ff3333;
$notify: #ff3333;
$active: #12d651;
$clr: #efefef;
$clr-2: #cccccc;
$clr-3: #4f545c;
$clr-4: #e4e4e4;
$clr-5: #ed4245;
$clr-6: #ebedef;
$clr-7: #4f5660;
$clr-8: #6a7480;
$clr-9: #f2f3f5;
$clr-10: #888888;
$premium: #c41d7f;

//shadows
$minimum-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
$small-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
$medium-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);
$strong-shadow:  0px 0px 24px 0px rgba(0, 0, 0, 0.20);

// borders
$border: #dbdde5;
$border-2: #e7e8f2;
$border-3: #bbbbbb;
$border-3: #c41d7f;
$border-4: #aaaaaa;
$border-5: #e7e7e7;

// socials
$facebook: #1876f1;
$twitter: #00aced;
$telegram: #039be5;
$whatsapp: #25d366;
$envelope: $secondary;

//icons
$icon-bg: #F4F7FC;
$icon-color: #025928;
$icon-width: 69px;
$icon-height: 69px;
$icon-radius: 69px;


// shadows
$drop-shadow-deep: 5px 5px 15px rgba(#000, 15%);
// Grid Breakpoints
$breakpoints: (
    xs2: 350px,
    xs: 450px,
    xsm2: 400px,
    xsm: 480px,
    sm: 576px,
    md: 768px,
    md2: 900px,
    lg: 991.98px,
    xl: 1200px,
    xxl: 1320px,
    xxxl: 1400px,
);

// layout
$containerPadding: 5vw;
$containerWidth: 100%;
$container-2: calc(1300px - $containerPadding);
$container-3: calc(1410px - $containerPadding);
$minSideNavigation: 180px;
$sideNavigation: 348px;
$dashboardRightSide: 404px;
$minDashboardRightSide: 230px;
$courseDetailsRight: 400px;
$eCard: 320px;

// fonts
$font: 'Montserrat', sans-serif;
$btn-font: 'Poppins', sans-serif;
$font-2: 'Poppins', sans-serif;
// $font-2: 'Roboto Slab', serif;

// colors
$body-bg: #F4F7FC;
$section-bg: #F4F7FC;
$footer-bg: #D967040D;

$cta-btn: #D96704;
$cta-btn-hover: #F79403;
$primary: #025928;
$primary-2: #3C8C30;

$bg-color: #F4F7FC;
$description: #666666;
$description-2: #4A5569;
$dark-text: #2A3344;


$gradient: linear-gradient(180deg, #025928 0%, #3C8C30 100%);
$gradient-2: linear-gradient(180deg, #025928 0%, #13A692 100%);

$white: #ffffff;
$black: #000000;
$soft-stoke: #dddddd;
$red: #ff3333;

$secondary: #D5FFD5;
$secondary-btn-bg: #F7FFF7;
$secondary-btn-hover: #E4FFE4;
$secondary-btn-border: #3C8C30;

$secondary-underline: #fdd96b;
// $secondary : #fdd96b;
// $secondary: #ff8b26;
// $secondary-2: #ffab17;
// $secondary-3: #ffb27a;
$secondary-4: #dff6fe;

$tertiary-border: #025928;
$neutral: #111111;
$neutral-2: #353535;
$neutral-3: #5e5e5e;
$neutral-4: #b5b5b5;
$neutral-5: #f8f8f8;
$gray: #c3c3c3;
$success: #36b236;
$error: #ff3333;
$notify: #ff3333;
$active: #12d651;
$clr: #efefef;
$clr-2: #cccccc;
$clr-3: #4f545c;
$clr-4: #e4e4e4;
$clr-5: #ed4245;
$clr-6: #ebedef;
$clr-7: #4f5660;
$clr-8: #6a7480;
$clr-9: #f2f3f5;
$clr-10: #888888;
$premium: #c41d7f;

//shadows
$minimum-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
$small-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
$medium-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);
$strong-shadow:  0px 0px 24px 0px rgba(0, 0, 0, 0.20);

// borders
$border: #dbdde5;
$border-2: #e7e8f2;
$border-3: #bbbbbb;
$border-3: #c41d7f;
$border-4: #aaaaaa;
$border-5: #e7e7e7;

// socials
$facebook: #1876f1;
$twitter: #00aced;
$telegram: #039be5;
$whatsapp: #25d366;
$envelope: $secondary;

//icons
$icon-bg: #F4F7FC;
$icon-color: #025928;
$icon-width: 69px;
$icon-height: 69px;
$icon-radius: 69px;


// shadows
$drop-shadow-deep: 5px 5px 15px rgba(#000, 15%);
