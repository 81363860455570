@import "../../../../styles/variables";

.LandscapingmainConatiner {
  position: relative;

  .LandscapingCorner {
    position: absolute;
    left: 0;
    right: auto;
    top: 0;
    z-index: -1;
  }
}
.lanscContainer {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding: 40px 15px;

  .lanscSubContainer {
    width: 100%;
    max-width: 820px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: $black;

    .lanscHeading {
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      text-transform: uppercase;
    }
    .lanscsubHeading {
      font-weight: 700;
      font-size: 24px;
      text-transform: uppercase;
    }
    .lanscDisc {
      font-size: 16px;
      line-height: 28px;
      margin: 15px 0 20px 0;
    }
    .lansc_x222responsive {
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-bottom: 30px;
      @media(max-width: map-get($breakpoints, "md")) {
        display: flex;
      }

      // :nth-child(1) {
      //   width: 80%;
      //   margin: auto;
      //   height: 45px;
      //   color: $white;
      //   padding: 5px;
      //   font-size: 16px;
      //   font-weight: 500;
      //   background-color: $primary;
      //   border-radius: 50px;
      //   border: none;
      //   cursor: pointer;
      // }
      // :nth-child(2) {
      //   width: 80%;
      //   margin: auto;
      //   height: 45px;
      //   color: $primary;
      //   padding: 5px;
      //   font-size: 16px;
      //   font-weight: 500;
      //   background-color: transparent;
      //   border-radius: 50px;
      //   outline: none;
      //   border: 1px solid $primary;
      //   cursor: pointer;
      // }
    }
  }

  .lanscBoxImage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .lansc_x1 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;

      .lansc_x11 {
        height: 250px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }

      .lansc_x12 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .lansc_x121 {
          height: 250px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
        .lansc_x122 {
          height: 250px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .lansc_x13 {
        height: 250px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }
    }

    .lansc_x2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .lansc_x21 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;

        .lansc_x211 {
          height: 250px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
        .lansc_x212 {
          height: 520px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .lansc_x22 {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .lansc_x221 {
          height: 550px;
          overflow: hidden;
          border-radius: 15px;
          object-fit: cover;
        }
        .lansc_x222 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          // :nth-child(1) {
          //   width: 80%;
          //   margin: auto;
          //   height: 65px;
          //   color: $white;
          //   padding: 5px;
          //   font-size: 16px;
          //   font-weight: 500;
          //   background-color: $primary;
          //   border-radius: 50px;
          //   border: none;
          //   cursor: pointer;
          // }
          // :nth-child(2) {
          //   width: 80%;
          //   margin: auto;
          //   height: 65px;
          //   color: $primary;
          //   padding: 5px;
          //   font-size: 16px;
          //   font-weight: 500;
          //   background-color: transparent;
          //   border-radius: 50px;
          //   outline: none;
          //   border: 1px solid $primary;
          //   cursor: pointer;
          // }
        }
      }
    }
  }
}

@media screen and (max-width: 868px) {
  .lanscContainer .lanscBoxImage {
    grid-template-columns: repeat(1, 1fr);
  }
  .LandscapingmainConatiner {
    .LandscapingCorner {
      height: 500px;
    }
  }
}

@media screen and (max-width: 642px) {
  .lanscContainer .lanscBoxImage {
    gap: 10px;

    .lansc_x1 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;

      .lansc_x11 {
        height: 112px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }

      .lansc_x12 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        .lansc_x121 {
          height: 112px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
        .lansc_x122 {
          height: 112px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .lansc_x13 {
        height: 112px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }
    }

    .lansc_x2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .lansc_x21 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;

        .lansc_x211 {
          height: 82px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
        .lansc_x212 {
          height: 169px;
          border-radius: 15px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .lansc_x22 {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .lansc_x221 {
          height: 169px;
          overflow: hidden;
          border-radius: 15px;
          object-fit: cover;
        }
        .lansc_x222 {
          display: none;
        }
      }
    }
  }
  .lanscContainer .lanscSubContainer {
    .lanscHeading {
      font-size: 24px;
      line-height: 40px;
    }
    .lanscsubHeading {
      font-size: 18px;
    }
    .lanscDisc {
      font-size: 16px;
      line-height: 28px;
    }
    .lansc_x222 {
      display: flex;
    }
  }
}
