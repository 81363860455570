// imports
@import '../../../styles/variables';

.section {
    position: relative;
    padding: 0;
    @media (max-width: map-get($breakpoints, lg)) {
        padding: 0;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding: 0;
    }
}
