@import "../../../../styles/variables";

.heromainContainer {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 5rem;

  .hero_corner {
    position: absolute;
    top: 0;
    left: 0;
    height: 250px;
  }
  .heroContainer {
    max-width: 1370px;
    width: 100%;
    margin: auto;
    padding: 15px;
    position: relative;

    .lefttext {
      position: absolute;
      top: 0px;
      max-width: 420px;

      :nth-child(1) {
        font-size: 65px;
        font-weight: 700;
        text-transform: uppercase;
      }
      :nth-child(2) {
        height: 59px;
        border-radius: 50px;
        width: 190px;
        overflow: hidden;
      }
    }

    .righttext {
      position: absolute;
      right: 20px;
      bottom: 0px;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      :nth-child(1) {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
      :nth-child(3) {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
      :nth-child(2) {
        // font-size: 10px;
        text-decoration: underline;
      }

      .btn_arrow {
        position: relative;
        .heroBtn {
          width: 300px;
          height: 67px;
          border-radius: 50px;

          font-size: 17px;
          font-weight: 500;
          text-align: center;
          padding: 5px;

          border: none;
          cursor: pointer;
          margin-top: 0.7rem;
          background-color: $primary;
          color: $white;
          border-radius: 58px;
          &:hover {
            background-color: $primary-2;
            border-color: $tertiary-border;
          }

          .btnGradient {
            background: $gradient;
          }
        }
        .round_arrow {
          // width: 60px;
          height: 150px;
          position: absolute;
          left: -40px;
          bottom: 50px;
        }
      }
    }

    .heroImage_Container {
      position: relative;
      .heroImage {
        width: 100%;
        transform: rotateX(0deg) rotateY(180deg);
      }
      .imagecircles {
        position: absolute;
        left: 20px;
        bottom: 50px;
        display: flex;
        justify-content: space-between;
        width: 60%;
        align-items: center;
        gap: 20px;

        .imagecircle {
          border: 3px solid $white;
          background-color: #d9d9d994;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 150px;
          border-radius: 100%;
          text-align: center;
          color: $black;

          font-weight: 500;
          gap: 10px;
          font-size: 15px;
          .x_icon {
            width: 25px;
          }
        }
      }
    }
  }

  .hero_xs {
    max-width: 1020px;
    width: 100%;
    padding: 0 15px;
    margin: auto;
    position: relative;
    margin-top: 8rem;

    .hero_ellipes1 {
      width: 120px;
      height: 120px;
      position: absolute;
      left: -20px;
      top: 0px;
    }
    .hero_ellipes2 {
      width: 120px;
      height: 120px;
      position: absolute;
      right: -20px;
      top: 0px;
    }

    .circle_x {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      .circle_x1 {
        width: 215px;
        height: 215px;
        border-radius: 100%;
        border: 1px solid $primary;
        padding: 5px;
        overflow: hidden;
        position: relative;

        .x1_image {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          overflow: hidden;
          background-color: $primary !important;
          opacity: 80% !important;
        }
        
        .x1_text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          :nth-child(1) {
            font-weight: 700;
            color: $white;
            font-size: 25px;
          }
          :nth-child(2) {
            font-size: 20px;
            font-weight: 500;
            color: $white;
            padding-top: 5px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .heromainContainer {
    .heroContainer {
      .lefttext {
        :nth-child(1) {
          padding-left: 10px;
          padding-top: 10px;
          font-size: 40px;
          font-weight: 700;
          text-transform: uppercase;
        }
        :nth-child(2) {
          height: 30px;
          width: 100px;
        }
      }

      .righttext {
        bottom: 20px;
        width: 30%;
        gap: 5px;

        :nth-child(1) {
          font-size: 18px;
          line-height: 20px;
        }
        :nth-child(3) {
          font-size: 12px;
          line-height: 12px;
        }
        :nth-child(2) {
          font-size: 8px;
        }
        .btn_arrow {
          .heroBtn {
            width: 150px;
            height: 40px;
            font-size: 12px;
            margin-top: 0.1rem;
          }
          .round_arrow {
            height: 60px;
            left: -25px;
            bottom: 20px;
          }
        }
      }

      .heroImage_Container {
        position: relative;
        .heroImage {
          width: 100%;
          transform: rotateX(0deg) rotateY(180deg);
        }
        .imagecircles {
          left: 10px;
          bottom: 30px;
          gap: 5px;

          .imagecircle {
            width: 100px;
            height: 100px;
            padding: 3px;
            gap: 5px;
            font-size: 10px;
            .x_icon {
              width: 17px;
            }
          }
        }
      }
    }
    .hero_xs {
      .circle_x {
        gap: 1rem;
        .circle_x1 {
          width: 200px;
          height: 200px;
          .x1_text {
            :nth-child(1) {
              font-size: 20px;
            }
            :nth-child(2) {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .heromainContainer {
    .heroContainer {
      .righttext {
        bottom: -20px;
        width: 30%;
        gap: 5px;

        :nth-child(1) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .heromainContainer {
    .heroContainer {
      .lefttext {
        :nth-child(1) {
          padding-left: 10px;
          padding-top: 10px;
          font-size: 30px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .righttext {
        bottom: -20px;
        width: 30%;
        gap: 5px;
        button {
          max-width: 190px;
        }
        :nth-child(1) {
          font-size: 16px;
          line-height: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .heromainContainer {
    margin-bottom: 2rem;
    .heroContainer {
      .heroImage_Container {
        .imagecircles {
          left: 5px;
          bottom: 5px;
          gap: 5px;
          flex-wrap: wrap-reverse;
          padding: 0 20px;
          .imagecircle {
            width: 50px;
            height: 50px;
            padding: 3px;
            gap: 2px;
            font-size: 4px;
            .x_icon {
              width: 10px;
            }
          }
        }
      }
    }

    .hero_xs {
      margin-top: 5rem;

      .hero_ellipes1 {
        width: 60px;
        height: 60px;
      }
      .hero_ellipes2 {
        width: 60px;
        height: 60px;
      }

      .circle_x {
        gap: 0.5rem;
        .circle_x1 {
          width: 110px;
          height: 110px;
          .x1_image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
          }
          .x1_text {
            :nth-child(1) {
              font-size: 14px;
            }
            :nth-child(2) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 563px) {
  .heromainContainer {
    .heroContainer {
      padding: 0 15px;

      .lefttext {
        :nth-child(1) {
          font-size: 25px;
          line-height: 30px;
        }
        :nth-child(2) {
          height: 25px;
          width: 80px;
        }
      }

      .righttext {
        bottom: -50px;
        width: 30%;
        gap: 5px;
        button {
          max-width: 160px;
        }
        :nth-child(1) {
          font-size: 12px;
          line-height: 15px;
        }
        :nth-child(3) {
          font-size: 8px;
          line-height: 10px;
        }
        :nth-child(2) {
          font-size: 5px;
        }
        .btn_arrow {
          .heroBtn {
            width: 100px;
            height: 30px;
            font-size: 8px;
          }
          .round_arrow {
            height: 50px;
            left: -20px;
            bottom: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .heromainContainer {
    .heroContainer {
      .lefttext {
        :nth-child(1) {
          padding-left: 10px;
          padding-top: 10px;
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .heroImage_Container {
        .imagecircles {
          left: 5px;
          bottom: 5px;
          gap: 5px;
          flex-wrap: wrap-reverse;
          padding: 0 10px;
          .imagecircle {
            width: 40px;
            height: 40px;
            padding: 3px;
            gap: 2px;
            font-size: 3px;
            .x_icon {
              width: 10px;
            }
          }
        }
      }
      .righttext {
        bottom: -30px;
        width: 30%;
        gap: 5px;
        right: 15px;

        :nth-child(1) {
          font-size: 10px;
          line-height: 10px;
        }
        button {
          max-width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .heromainContainer {
    .heroContainer {
      .lefttext {
        :nth-child(1) {
          padding-left: 10px;
          padding-top: 10px;
          font-size: 15px;
          line-height: 20px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .heroImage_Container {
        .imagecircles {
          left: 5px;
          bottom: 5px;
          gap: 5px;
          flex-wrap: wrap-reverse;
          padding: 0 10px;
          .imagecircle {
            width: 35px;
            height: 35px;
            padding: 3px;
            gap: 1.5px;
            font-size: 3px;
            .x_icon {
              width: 10px;
            }
          }
        }
      }
      .righttext {
        bottom: -40px;
        width: 30%;
        gap: 5px;
        right: 15px;

        :nth-child(1) {
          font-size: 7px;
          line-height: 8px;
        }
        button {
          max-width: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 345px) {
  .heromainContainer {
    .heroContainer {
      padding: 0 15px;

      .lefttext {
        :nth-child(1) {
          font-size: 11px;
          line-height: 15px;
        }
        :nth-child(2) {
          height: 20px;
          width: 60px;
        }
      }

      .heroImage_Container {
        .imagecircles {
          left: 5px;
          bottom: 5px;
          gap: 5px;
          flex-wrap: wrap-reverse;
          padding: 0 10px;
          .imagecircle {
            width: 35px;
            height: 35px;
            padding: 3px;
            gap: 1.5px;
            font-size: 3px;
            .x_icon {
              width: 10px;
            }
          }
        }
      }

      .righttext {
        bottom: -40px;
        width: 30%;
        gap: 5px;
        right: 15px;

        :nth-child(1) {
          font-size: 7px;
          line-height: 8px;
        }
        :nth-child(3) {
          font-size: 7px;
          line-height: 8px;
        }
        :nth-child(2) {
          font-size: 5px;
        }

        .btn_arrow {
          .heroBtn {
            width: 80px;
            height: 25px;
            font-size: 6px;
            margin-top: 0;
          }
          .round_arrow {
            height: 40px;
            left: -15px;
            bottom: 15px;
          }
        }
      }
    }
    .hero_xs {
      .circle_x {
        gap: 0.3rem;
        .circle_x1 {
          width: 90px;
          height: 90px;
          padding: 2px;
          .x1_text {
            :nth-child(1) {
              font-size: 12px;
            }
            :nth-child(2) {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}
